<template>
  <main>
    <NavigationBar
      :currentTab="activeTab"
      :tabs="newHireTabs"
      :leaderTabs="leaderTabs"
      @tab-click="tabClick"
      @sign-out="signOutHandler"
    />
    <section class="main-view">
      <MainHeader />
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component @tab-click="tabClickWithParam" :is="Component" />
        </transition>
      </router-view>
    </section>
  </main>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import NavigationBar from "@/components/NavigationBar";
import MainHeader from "@/components/MainHeader";
import AuthAPI from "@/api/AuthAPI";
import newHireTabs from "@/data/navigationTabs/newHireTabs";
import leaderTabs from "@/data/navigationTabs/leaderTabs";
import { userNames } from "@/store/modules/user";
import { useCookies } from "@vueuse/integrations/useCookies";
const { get } = useCookies(["onboarding_journey"], {
  doNotParse: false,
  autoUpdateDependencies: false,
});
const router = useRouter();

// onMounted(async () => {
//   const loggedIn = await get('onboarding_journey')
//   if (!loggedIn) await router.push('/login')
// })

const route = useRoute();
const currentPath = route.path;
const activeTab = ref(currentPath);

const store = useStore();
const user = store.state.user;

const tabClickWithParam = async (path, param) => {
  if (path !== "task") activeTab.value = path;
  console.log(path);
  await router.push(path + param);
};
const tabClick = async (path) => {
  activeTab.value = path;
  await router.push(path);
};
// onMounted( async() => {
//    if (!user.homeStyle) {
//       await router.push('/personalize/select-house')
//     } else if (!user.avatar) {
//       await router.push('/personalize/avatar-builder')
//     } else {
//       await router.push('/')
//     }
// })
const signOutHandler = async () => {
  await AuthAPI.logout();
  await store.commit(userNames.resetUser);
  await router.push("/login");
};
</script>

<style scoped>
main {
  display: flex;
  min-height: 100vh;
  color: #5f6562;
}

.main-view {
  display: flex;
  flex-direction: column;
  padding: 40px 70px;
  /* width: calc(100% - 360px); */
}
</style>
