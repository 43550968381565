import house from '@/assets/icons/house'
import settingUp from '@/assets/icons/settingUp'
import courses from '@/assets/icons/courses'
import resource from '@/assets/icons/resourse'
import support from '@/assets/icons/support'
import hr from '@/assets/icons/hr'

export default [
  {
    name: 'Dashboard',
    path: '/',
    icon: house
  },
  {
    name: 'HR',
    path: '/hr',
    icon: hr
  },
  {
    name: 'Setting Up',
    path: '/setting-up',
    icon: settingUp
  },
  {
    name: 'Training',
    path: '/courses',
    icon: courses
  }
  // {
  //   name: 'Resource Library',
  //   path: '/resource-library',
  //   icon: resource
  // },
  // {
  //   name: 'Support',
  //   path: '/support',
  //   icon: support
  // }
]
