import hires from '@/assets/icons/hires'


export default [
  {
    name: 'My New Hires',
    path: '/new-hires',
    icon: hires
  },

  // {
  //   name: 'Resource Library',
  //   path: '/resource-library',
  //   icon: resource
  // },
  // {
  //   name: 'Support',
  //   path: '/support',
  //   icon: support
  // }
]
