<template>
  <header class="profile-line">
    <div class="avatar">
      <AvatarCircle
        size="100"
        :avatar="avatar"
        should-show-third
      />
      <div style="margin-left: 15px">
        <p class="avatar-welcome">Welcome!</p>
        <h2 class="avatar-name">{{ store.state.user.first_name }}</h2>
        <!-- <h2 class="avatar-name">{{store.state.user.firstName}}</h2> -->
      </div>
    </div>
    <!-- <div style="display: flex; align-items: center; column-gap: 25px">
      <div style="position: relative; display: flex; align-items: center">
        <input
          v-model.trim="searchQuery"
          class="search"
          placeholder="Search"
          :style="{
            background: searchQuery ? 'white' : 'rgba(196, 196, 196, 0.19)',
          }"
        />
        <div v-html="search" class="search-icon" /> -->
      <!-- </div> -->
      <!-- <NotificationSystem /> -->
    <!-- </div> -->
  </header>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import search from "@/assets/icons/search";
import AvatarCircle from "@/components/AvatarCircle";
import NotificationSystem from "@/components/NotificationSystem";
const store = useStore();
  const avatar = ref()
onMounted( async() => {
  avatar.value = await store.state.avatar
})

const searchQuery = ref("");
</script>

<style scoped>
.profile-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 43px;
}

.avatar {
  display: flex;
  align-items: center;
  align-self: center;
}

.avatar-welcome {
  margin: 0;
  text-transform: uppercase;
  color: #ff8217;
}

.avatar-name {
  margin: 0;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 36px;
  color: black;
}

.search {
  height: 55px;
  width: 260px;
  border: 0;
  border-radius: 36px;
  padding-left: 32px;
  padding-right: 60px;
  outline: 0;
  font-size: 14px;
  color: #5f6562;
}

.search::placeholder {
  color: #c9ccce;
}

.search-icon {
  position: absolute;
  right: 20px;
  fill: #c9ccce;
}
</style>
